import React from 'react';

const RadioField = ({
  id, name, value, onChange, options, inputClass,
}) => (
  options.map((option) => {
    const { key } = option;
    const optionId = `${id}-option-${key}`;

    return (
      <div className="Radio" key={key}>
        <input
          type="radio"
          className={`Radio-input ${inputClass}`}
          value={option.value}
          name={name}
          id={optionId}
          onClick={() => onChange(name, option.value)}
          defaultChecked={value === option.value}
        />
        <label htmlFor={optionId} className="Radio-label">{option.label}</label>
      </div>
    );
  })
);

export default RadioField;
