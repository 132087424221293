import { useState } from 'react';

// a simple hook for boolean toggles
//
export default function useToggle(defaultValue) {
  const [toggle, setToggle] = useState(defaultValue);
  return [toggle, () => {
    setToggle(!toggle);
  }];
}
