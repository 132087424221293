import React from 'react';

/**
 * Icon class implementing Google Material Symbols
 * https://fonts.google.com/icons
 * All icons are referenced by their name in lowercase with underscores
 * e.g."Arrow Forward iOS" -> arrow_forward_ios
 */
const Icon = ({
  icon,
  outline = false,
  className = '',
}) => (
  <span
    className={`material-symbols-outlined ${outline ? 'is-outline' : ''} ${className}`}
  >
    {icon}
  </span>
);
export default Icon;
