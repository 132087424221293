import React from 'react';

const Checkbox = ({
  id, name, value, disabled, label, containerClass = '', required, error, hint, onChange, wrapper = true, dynamic = false,
}) => {
  const html = (
    <div className="Checkbox">
      <label htmlFor={id} className="Checkbox-label">
        <input
          type="checkbox"
          className="Checkbox-input"
          id={id}
          name={name}
          disabled={disabled}
          {...(dynamic ? { checked: !!value } : { defaultChecked: !!value })}
          onChange={evt => onChange(name, evt.target.checked)}
        />
        {label && (
          <span>
            {label}
            {required && <span className="Form-required">&nbsp;*</span>}
          </span>
        )}
      </label>
    </div>
  );

  const finalHtml = !wrapper ? html : (
    <div className={`Form-item ${containerClass}`}>
      {html}
      {hint && <p className="Form-hint">{hint}</p>}
      {error && <p className="Form-error">{error}</p>}
    </div>
  );

  return finalHtml;
};

export default Checkbox;
