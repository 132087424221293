import React, { useState } from 'react';

const PasswordField = ({
  id, name, onChange, inputClass,
}) => {
  const [showPassword, _setShowPassword] = useState(false);

  return (
    <input
      type={showPassword ? 'text' : 'password'}
      className={`Form-input ${inputClass}`}
      name={name}
      id={id || name}
      onChange={(evt) => onChange(name, evt.target.value)}
    />
  );
};

export default PasswordField;
