import React from 'react';
import Select from 'react-select';

const SelectField = ({
  id,
  name,
  value,
  options,
  onChange,
  inputClass,
  isMulti = false,
  isSearchable = true,
  disabled = false,
}) => (
  <Select
    name={name}
    id={id}
    value={options.find(o => o.value === value)}
    className={`Form-input ${inputClass}`}
    options={options}
    isMulti={isMulti}
    isSearchable={isSearchable}
    isDisabled={disabled}
    onChange={option => onChange(name, option.value)}
    classNamePrefix="react-select"
  />
);

export default SelectField;
