import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

const DateField = ({
  id,
  name,
  value = null,
  placeholder = '',
  onChange,
  required = false,
  disabled = false,
}) => {
  const reactDatePickerChangeHandler = date => {
    onChange(name, date);
  };

  return (
    <DatePicker
      international
      name={name}
      selected={value}
      placeholderText={placeholder}
      onChange={reactDatePickerChangeHandler}
      id={id}
      {...(required ? { required: true } : {})}
      className={`Form-input DateField ${disabled ? 'b-displayDisabled' : ''}`}
      disabled={disabled}
    />
  );
};

export default DateField;
