import React, { useState } from 'react';

const ToastStates = {
  INFO: { icon: 'fa fa-info', modifier: 'Toast--info' },
  WARNING: { icon: 'fa fa-exclamation', modifier: 'Toast--warning' },
  ERROR: { icon: 'fa fa-times', modifier: 'Toast--error' },
  SUCCESS: { icon: 'fa fa-check', modifier: 'Toast--success' },
};

const Toast = ({
  title, state = ToastStates.INFO, position = 'topRight', className = '', closeCallback, children,
}) => {
  const [showSelf, setShowSelf] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  const transitionOut = () => {
    setIsClosing(true);
    setTimeout(() => setShowSelf(false), 1000);
  };

  if (showSelf === false) return null;

  return (
    <div className={`Toast Toast--${position} ${state.modifier} ${className} ${isClosing && 'is-closing'}`}>
      <a
        className="Toast-closeButton"
        onClick={closeCallback || transitionOut}
      >
        <i className="fa fa-times" title="Close" />
      </a>
      <div className="Toast-content">
        <i className={`Toast-icon ${state.icon}`} />
        <div className="Toast-text">
          <span className="Toast-title">{title}</span>
          <div className="Toast-message">{children}</div>
        </div>
      </div>
    </div>
  );
};

export { ToastStates };
export default Toast;
