import React from 'react';
import TextField from './Fields/TextField';
import PasswordField from './Fields/PasswordField';
import PhoneField from './Fields/PhoneField';
import SelectField from './Fields/SelectField';
import RadioField from './Fields/RadioField';
import DateField from './Fields/DateField';
import NumberField from './Fields/NumberField';

const Field = props => {
  const {
    inputClass, error, isError, name,
  } = props;
  const defaultProps = {
    containerClass: '',
    labelClass: '',
    inputClass: `${inputClass} ${(isError || error) ? 'has-error' : ''}`,
    id: name,
  };

  const allProps = { ...defaultProps, ...props };

  const renderInput = () => {
    switch (allProps.type) {
      case 'text':
        return <TextField {...allProps} />;
      case 'password':
        return <PasswordField {...allProps} />;
      case 'tel':
        return <PhoneField {...allProps} />;
      case 'select':
        return <SelectField {...allProps} />;
      case 'radio':
        return <RadioField {...allProps} />;
      case 'date':
        return <DateField {...allProps} />;
      case 'number':
        return <NumberField {...allProps} />;
      default:
        throw new Error(`${allProps.type} is an invalid input type`);
    }
  };

  return (
    <div className={`Form-item ${allProps.containerClass}`}>
      <label className={`Form-label ${allProps.labelClass}`} htmlFor={allProps.id}>
        {allProps.label}
        {allProps.required && <span className="Form-required">&nbsp;*</span>}
      </label>
      {renderInput()}
      {allProps.hint && <p className="Form-hint">{allProps.hint}</p>}
      {allProps.error && <p className="Form-error" dangerouslySetInnerHTML={{ __html: allProps.error }} />}
    </div>
  );
};

export default Field;
