import React from 'react';

const TextField = ({
  id, name, value, onChange, inputClass, placeholder = '', milestonesRequired = false, hintText = null,
}) => (
  <>
    {hintText && <p className="Form-hint">{hintText}</p>}
    <input
      type="text"
      className={`Form-input ${inputClass}`}
      defaultValue={value}
      placeholder={placeholder}
      name={name}
      {...(milestonesRequired ? { required: true } : {})}
      id={id}
      onChange={evt => onChange(name, evt.target.value)}
    />
  </>
);

export default TextField;
