import { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { percentage, secondsRemaining } from "../utils/helpers";

function useJobProgressMonitor(monitor_id) {
  const [monitor, setMonitor] = useState({
    percentage_complete: null,
  });

  const timeout = useRef(null);

  const { result } = monitor;

  useEffect(() => {
    if (!result && timeout.current === null) {
      timeout.current = setTimeout(() => {
        $.getJSON(`/api/v1/job_progress_monitors/${monitor_id}.json`, (newMonitor) => {
          const {
            rows_processed, total_rows_to_process, seconds_elapsed, result,
          } = newMonitor;
          newMonitor.percentage_complete = null;
          if(total_rows_to_process !== null) newMonitor.percentage_complete = total_rows_to_process > 0 ? percentage(rows_processed, total_rows_to_process) : 100;

          newMonitor.seconds_remaining = secondsRemaining(seconds_elapsed, newMonitor.percentage_complete);
          newMonitor.complete = (result !== null);

          setMonitor(newMonitor);
          timeout.current = null;
        });
      }, 300);
    }
  });

  return monitor;
}

export default useJobProgressMonitor;
