import React from 'react';

const GridPanels = ({
  rows = 1,
  cols = 1,
  children,
  className,
  ...props
}) => (
  <div
    className={`GridPanels ${className}`}
    style={{
      gridTemplateRows: `repeat(${rows}, 1fr)`, gridTemplateColumns: `repeat(${cols}, 1fr)`,
    }}
    {...props}
  >
    {children}
  </div>
);
export default GridPanels;
