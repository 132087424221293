import client from '../api/axiosClient';
import appsignal from '../utils/errorReporting';

const UserEventTracker = {
  sendEvent: async function (event_name, event_properties) {
    try {
      await client.post('/api/v1/trackable_events', {
        trackable_event: {
          event_name,
          event_properties,
        },
      });
    } catch (e) {
      if (e.response?.status === 422) appsignal.sendError(new Error(`Rejected trackable event: ${event_name} - ${JSON.stringify(event_properties)}`));
    }
  },
};

export default UserEventTracker;
