const KubicleColors = {
  Black: '#000000',
  White: '#FFFFFF',

  Denim0: '#00264d',
  Denim1: '#004080',
  Denim2: '#1176BB',
  Denim3: '#70ADD6',
  Denim4: '#B7D5EA',
  Denim5: '#D9E7F4',
  Denim6: '#EBF5FB',

  VeniceBlue0: '#001A3D',
  VeniceBlue1: '#002A5A',
  VeniceBlue2: '#003663',
  VeniceBlue3: '#1E4C72',
  VeniceBlue4: '#5A7391',
  VeniceBlue5: '#A3B2C6',
  VeniceBlue6: '#E5EDF4',

  MineShaft0: '#1A1A1A',
  MineShaft1: '#2B2B2B',
  MineShaft2: '#333333',
  MineShaft3: '#5C5C5C',
  MineShaft4: '#858585',
  MineShaft5: '#EDEDED',
  MineShaft6: '#F2F2F2',

  Casper0: '#7A8F9D',
  Casper1: '#91A5B8',
  Casper2: '#ACC2D4',
  Casper3: '#BDCEDD',
  Casper4: '#CDDAE5',
  Casper5: '#E6ECF2',
  Casper6: '#F2F7FB',

  RobinsEgg0: '#004D4B',
  RobinsEgg1: '#00807A',
  RobinsEgg2: '#00A9A2',
  RobinsEgg3: '#6BDAD6',
  RobinsEgg4: '#B4ECEA',
  RobinsEgg5: '#E0F9F7',
  RobinsEgg6: '#E7F7F6',

  FuelYellow0: '#8C5E14',
  FuelYellow1: '#C37C1E',
  FuelYellow2: '#F3A536',
  FuelYellow3: '#FFC166',
  FuelYellow4: '#FFE0B2',
  FuelYellow5: '#FFF7EC',
  FuelYellow6: '#FFF6E8',

  Grenadier0: '#801E02',
  Grenadier1: '#A82806',
  Grenadier2: '#D43008',
  Grenadier3: '#DC5837',
  Grenadier4: '#E5846A',
  Grenadier5: '#F2C0B4',
  Grenadier6: '#FBE8E4',

  RoyalAmethyst0: '#2D003D',
  RoyalAmethyst1: '#4A007A',
  RoyalAmethyst2: '#7200B7',
  RoyalAmethyst3: '#A14EDD',
  RoyalAmethyst4: '#C599F2',
  RoyalAmethyst5: '#EAD6FA',
  RoyalAmethyst6: '#F8F0FA',
};
export default KubicleColors;
