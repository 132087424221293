import React from 'react';

const NumberField = ({
  id, name, value, onChange, inputClass,
}) => (
  <input
    type="number"
    className={`Form-input ${inputClass}`}
    defaultValue={value}
    name={name}
    id={id}
    onChange={(evt) => onChange(name, evt.target.value)}
  />
);

export default NumberField;
