import moment from 'moment';
import { mapValues } from 'lodash';

const date90ago = moment().subtract(90, 'days');
const date60ago = moment().subtract(60, 'days');
const date30ago = moment().subtract(30, 'days');

const cohorts = {
  plus90Days: { from: moment().subtract(30, 'years'), to: date90ago },
  plus60Days: { from: date90ago, to: date60ago },
  plus30Days: { from: date60ago, to: date30ago },
  // to fully include today's date
  last30Days: { from: date30ago, to: moment().startOf('day').add(1, 'day') },
  noDate: null,
};

export const dayCohorts = date => {
  const dateValue = moment(date);

  if (!date || !dateValue.isValid()) return 'noDate';
  if (dateValue < date90ago.startOf('day')) return 'plus90Days';

  if (dateValue < date60ago.startOf('day')) return 'plus60Days';

  if (dateValue < date30ago.startOf('day')) return 'plus30Days';

  if (dateValue <= moment().endOf('day')) return 'last30Days';
};

export const cohortToRange = cohort => cohorts[cohort];

export const cohortToFormattedRange = (cohort, format = 'DD.MM.YYYY') => {
  const range = cohortToRange(cohort);
  if (!range) return null;
  return mapValues(range, value => moment(value, format).format(format));
};

// Takes in minutes and converts to hours and minutes
// Optional parameters for customisation
// Usage example:
// let result = durationToText({duration: 60, hourText: 'h', minuteText: 'm', pluralize: false, space: false})
export const durationToText = ({
  duration,
  hourText = ' hour',
  minuteText = ' minute',
  pluralize = true,
  space = true,
}) => {
  const minutes = Math.floor(duration % 60);
  const hours = Math.floor(duration / 60);
  const hoursText = hourText + (hours > 1 && pluralize ? 's' : '');
  const minutesText = minuteText + (minutes > 1 && pluralize ? 's' : '');
  const hoursComposed = hours > 0 ? `${hours}${hoursText}${minutes > 0 && space ? ' ' : ''}` : '';
  const minutesComposed = hours === 0 || minutes > 0 ? `${minutes}${minutesText}` : '';
  return `${hoursComposed}${minutesComposed}`;
};

export const timeInWords = seconds => {
  const { round } = Math;

  if (seconds < 60) return ['Seconds', { count: seconds }];
  if (seconds < 3540) return ['Minutes', { count: round(seconds / 60) }];

  return ['Hours', { count: round(seconds / 3600) }];
};

export const greetingTime = () => {
  const hour = new Date().getHours();
  if (hour >= 0 && hour <= 11) return 'goodMorning';
  if (hour >= 12 && hour <= 17) return 'goodAfternoon';

  return 'goodEvening';
};
