/* eslint-disable no-restricted-globals */

import Appsignal from "@appsignal/javascript";
import { getEnvironment } from "./env";

const APPSIGNAL_KEYS = {
  development: '3492b797-1a72-4066-8728-4cb2bcf3f7bf',
  test: '',
  staging: '95624c70-dd88-45cc-b117-e17f3ce40f63',
  production: '7243b294-250c-409e-b657-fee1cf0a6eb4',
};

const environment = getEnvironment();
let appsignal;

if (environment === 'test') {
  appsignal = {
    sendError: () => {},
  };
} else if (environment === 'development') {
  appsignal = new Appsignal({ key: APPSIGNAL_KEYS[environment] });
} else {
  const revision = process.env.HEROKU_SLUG_COMMIT;
  appsignal = new Appsignal({ key: APPSIGNAL_KEYS[environment], revision });
}

export function listenAndReportErrors() {
  addEventListener("error", (event) => {
    appsignal.sendError(event.error);
  });

  addEventListener("onunhandledrejection", (event) => {
    appsignal.sendError(event.error);
  });
}

export default appsignal;
