import React from 'react';
import PhoneInput from "react-phone-number-input";

const PhoneField = ({
  id, name, value, onChange, defaultCountry, inputClass,
}) => (
  <PhoneInput
    placeholder="Enter phone number"
    international
    name={name}
    value={value}
    onChange={(newValue) => onChange(name, newValue)}
    id={id}
    defaultCountry={defaultCountry || 'US'}
    className={`Form-input ${inputClass}`}
  />
);

export default PhoneField;
