import React from 'react';
import LoadingCube from './LoadingCube';
import ProgressBar from './ProgressBar';

const ProgressBarSection = ({ percentage_complete, children }) => (
  percentage_complete !== null
    ? (
      <div className="container-full-width white progress-bar-section">
        {children}
        <ProgressBar percentageComplete={percentage_complete} />
      </div>
    ) : (
      <div className="container-full-width white progress-bar-section">
        <LoadingCube />
      </div>
    )
);

export default ProgressBarSection;
