import { createTheme } from '@mui/material/styles';
import { enUS } from '@mui/x-date-pickers/locales';
import KubicleColors from './kubicleColorsMui';

const nunito = '\'Nunito\', sans-serif';
const latoArialHelSans = 'Lato, Arial, Helvetica, sans-serif';
const panelContentRadius = 8;

const KubicleMuiTheme = createTheme(
  {
    palette: {
      fontFamily: {
        nunito,
        latoArialHelSans,
      },
      primary: {
        main: KubicleColors.Denim2,
      },
      secondary: {
        main: KubicleColors.VeniceBlue2,
      },
      info: {
        main: KubicleColors.Denim2,
      },
      success: {
        main: KubicleColors.RobinsEgg2,
      },
      warning: {
        main: KubicleColors.FuelYellow2,
      },
      error: {
        main: KubicleColors.Grenadier2,
      },
      grey: {
        50: KubicleColors.MineShaft6,
        100: KubicleColors.MineShaft5,
        500: KubicleColors.MineShaft4,
        900: KubicleColors.MineShaft2,
      },
      white: KubicleColors.White,
      black: KubicleColors.Black,
      text: {
        primary: KubicleColors.MineShaft2,
        secondary: KubicleColors.MineShaft4,
        neutral: KubicleColors.Casper2,
        dark: KubicleColors.VeniceBlue2,
        link: KubicleColors.Denim2,
      },
      icon: {
        light: KubicleColors.Casper2,
        bright: KubicleColors.Denim2,
        dark: KubicleColors.VeniceBlue2,
      },
      background: {
        neutral: KubicleColors.Casper4,
        lighter: KubicleColors.Casper3,
        light: KubicleColors.Casper5,
        bright: KubicleColors.Denim5,
        lightest: KubicleColors.Casper6,
      },
      colors: KubicleColors,
      shadows: {
        main: 'rgba(0, 0, 0, 0.4)',
        strong: 'rgba(0, 0, 0, 0.8)',
        weak: 'rgba(0, 0, 0, 0.2)',
      },
    },
    typography: {
      fontSize: 16,
      lineHeight: '20px',
      fontFamily: latoArialHelSans,
      textUnderlineOffset: '0.2em',
      color: 'inherit',
      textAlign: 'inherit',
      h1: {
        fontFamily: nunito,
        fontSize: 42,
        fontWeight: 'bold',
        lineHeight: '50px',
        color: KubicleColors.VeniceBlue2,
        display: 'block',
      },
      h2: {
        fontFamily: nunito,
        color: KubicleColors.VeniceBlue2,
        display: 'block',
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '40px',
      },
      h3: {
        fontFamily: nunito,
        fontWeight: 'bold',
        textTransform: 'none',
        color: KubicleColors.VeniceBlue2,
        display: 'block',
        fontSize: '28px',
        lineHeight: '36px',
      },
      h4: {
        fontFamily: nunito,
        fontWeight: 'bold',
        color: KubicleColors.VeniceBlue2,
        display: 'block',
        fontSize: '22px',
        lineHeight: '30px',
      },
      h5: {
        fontFamily: nunito,
        fontWeight: 'bold',
        color: KubicleColors.VeniceBlue2,
        display: 'block',
        fontSize: '18px',
        lineHeight: '24px',
      },
      h6: {
        fontFamily: nunito,
        fontWeight: 'bold',
        color: KubicleColors.MineShaft0,
        display: 'block',
        fontSize: '20px',
        lineHeight: '28px',
      },
      h7: {
        fontFamily: latoArialHelSans,
        fontWeight: 'bold',
        color: KubicleColors.VeniceBlue2,
        display: 'block',
        lineHeight: '20px',
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 16,
      },
      button: {
        fontSize: 16,
        textTransform: 'capitalize',
      },
      smaller: {
        fontSize: 14,
      },
      smallest: {
        fontSize: 12,
        fontFamily: latoArialHelSans,
      },
      footer: {
        fontSize: 13,
        fontFamily: latoArialHelSans,
      },
    },
    shape: {
      borderRadius: panelContentRadius,
      panelRadius: panelContentRadius * 2,
      panelShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    },
    spacing: 8,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          // Global user-agent resets(from old design_system/{_reset.css, _overrides.css})
          // NOTE: ONLY use this for global browser/agent and framework resets or for typography
          // overrides that cannot be achived within 'theme.typography'
          '*, *::before, *::after': {
            boxSizing: 'border-box',
          },
          '*': {
            margin: 0,
            textUnderlineOffset: '0.2em',
          },
          body: {
            backgroundColor: 'white',
            lineHeight: 1.5,
            webkitFontSmoothing: 'antialiased',
          },
          'img, picture, video, canvas, svg': {
            display: 'block',
            maxWidth: '100%',
          },
          'input, button, textarea, select': {
            font: 'inherit',
          },
          'p, h1, h2, h3, h4, h5, h6': {
            overflowWrap: 'break-word',
          },
          '.material-symbols-outlined': {
            fontSize: 'inherit',
            verticalAlign: '-0.2em',
            fontVariationSettings: '\'FILL\' 1, \'wght\' 600, \'GRAD\' 0, \'opsz\' 20',
            '&.is-outline': {
              fontVariationSettings: 'FILL\' 0, \'wght\' 600, \'GRAD\' 0, \'opsz\' 20',
            },
          },
          'p:last-child': {
            marginBottom: 0,
          },
          code: {
            fontWeight: 'normal',
          },
          section: {
            marginBottom: '16px',
          },
          summary: {
            margin: '8px 0',
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.shape.panelRadius,
            boxShadow: theme.shape.panelShadow,
          }),
        },
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.shape.panelRadius,
            boxShadow: theme.shape.panelShadow,
          }),
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiPaper-root': {
              borderRadius: theme.shape.panelRadius,
              boxShadow: theme.shape.panelShadow,
            },
          }),
        },
      },
      MuiPopper: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiPaper-root': {
              borderRadius: theme.shape.panelRadius,
              boxShadow: theme.shape.panelShadow,
            },
          }),
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: ({ _ }) => ({
            '&:before': {
              border: '1px solid #E6E8ED',
              boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
            },
            color: KubicleColors.White,
          }),
          tooltip: ({ theme }) => ({
            backgroundColor: KubicleColors.White,
            color: KubicleColors.MineShaft2,
            boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.2)',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }),
        },
      },
      MuiDatePicker: {
        defaultProps: {
          slotProps: {
            openPickerButton: {
              // TODO: this is not working
              color: KubicleColors.White,
            },
          },
        },
      },
      MuiDateField: {
        styleOverrides: {
          root: {
            '& input': {
              // TODO: this is not working and is overriden at use via sx
              marginBottom: 0,
              border: 'none',
            },
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiPickersYear-yearButton:hover, .MuiPickersCalendarHeader-switchViewIcon:hover': {
              backgroundColor: theme.palette.primary.main,
              color: KubicleColors.White,
            },
            '& .MuiPickersDay-root.Mui-selected, .MuiPickersDay-root:hover, .MuiPickersArrowSwitcher-button:hover': {
              backgroundColor: theme.palette.primary.main,
              color: KubicleColors.White,
            },
            '& .MuiButtonBase:hover': {
              backgroundColor: theme.palette.primary.main,
              color: KubicleColors.White,
            },
            '& .MuiPickersYear-yearButton': {
              padding: 0,
              margin: 0,
            },
          }),
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: 'none',
            boxShadow: 'none',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: ({ theme }) => ({
            border: 'none',
            boxShadow: 'none',
            '& div div div div >.MuiDataGrid-cell': {
              borderBottom: 'none',
              borderTop: 'none',
            },
            '& .MuiDataGrid-row': {
              borderBottom: 'none',
              '--rowBorderColor': 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
              borderTop: 'none',
              padding: '16px',
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none !important',
            },
            '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
              outline: 'none !important',
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: KubicleColors.Denim6,

              '&:hover': {
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: KubicleColors.MineShaft4,
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                marginLeft: '7px',
                fontWeight: 'bold',
                color: theme.palette.secondary.main,
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
            },
            '& .MuiDataGrid-columnHeader--sorted': {
              '& .MuiDataGrid-sortIcon, & .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 800,
                color: theme.palette.secondary.main,
              },

              '&:hover': {
                '& .MuiDataGrid-sortIcon, & .MuiDataGrid-columnHeaderTitle': {
                  color: KubicleColors.MineShaft4,
                },
              },
            },
            '& .MuiDataGrid-topContainer::after': {
              height: '0px',
            },
            '& .MuiDataGrid-columnHeaders': {
              border: 0,
              backgroundColor: KubicleColors.White,
              height: '61px',
              '& .MuiIconButton-root': {
                marginLeft: 2,
              },
              '& .MuiDataGrid-menuIcon': {
                marginRight: 2,
                '& MuiSvgIcon-root': {
                  color: 'rgba(0, 0, 0, 0.54)',
                },
              },
              '& .MuiIconButton-root:hover': {
                background: 'none !important',
              },
            },
          }),
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '& .MuiTableRow-root': {
              borderBottom: 'none',
              '& .MuiTableCell-body': {
                borderBottom: 'none',
              },
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: ({ theme }) => ({
            lineHeight: 1,
            paddingLeft: theme.spacing(3),
            '& .MuiTableRow-head': {
              borderLeft: 0,
              borderRight: 0,
              borderTop: 0,
              backgroundColor: KubicleColors.Denim6,
              '& .MuiTableCell-head': {
                fontWeight: 'bold',
                border: 0,
                color: theme.palette.secondary.main,
              },
            },
          }),
        },
      },
      MuiInput: {
        styleOverrides: {
          root: ({ theme }) => ({
            fontSize: theme.typography.fontSize,
            '& input[type=\'search\']': {
              border: 0,
              marginBottom: 0,
              paddingBottom: '8px',
            },
            '&.Mui-focused input[type=\'search\']': {
              border: 0,
              boxShadow: 'none',
            },
            '& .MuiIconButton-root[aria-label=\'Clear\']:hover': {
              backgroundColor: theme.palette.primary.main,
              color: KubicleColors.White,
            },
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            lineHeight: 1,
            paddingLeft: theme.spacing(3.2),
            paddingRight: theme.spacing(3.2),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: KubicleColors.White,
              boxShadow: `2px 2px 2px ${theme.palette.shadows.main}`,
            },
          }),
        },
      },
    },
  },
  enUS
);

export default KubicleMuiTheme;
