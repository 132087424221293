import React from 'react';

const GridPanel = ({
  col,
  row,
  width = 1,
  height = 1,
  invisible = false,
  children,
  className,
  isLink = false,
  href = '',
  ...props
}) => {
  if (isLink) {
    return (
      <a
        href={href}
        className={`GridPanels-panel is-link Panel ${invisible ? 'Panel--invisible' : ''} ${className}`}
        style={{
          gridColumnStart: col,
          gridColumnEnd: `span ${width}`,
          gridRowStart: row,
          gridRowEnd: `span ${height}`,
        }}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <div
      className={`GridPanels-panel Panel ${invisible ? 'Panel--invisible' : ''} ${className}`}
      style={{
        gridColumnStart: col,
        gridColumnEnd: `span ${width}`,
        gridRowStart: row,
        gridRowEnd: `span ${height}`,
      }}
      {...props}
    >
      {children}
    </div>
  );
};
export default GridPanel;
