import React, { useState } from 'react';

const SecondaryNotification = ({
  title, message, link, cta, type = '', style = {}, className = 'button', target = '_self', closeCallback,
}) => {
  const [showSelf, setShowSelf] = useState(true);
  if (showSelf === false) return null;
  return (
    <div className={`secondary-notification alert secondary ${type}`}>
      <a
        className="close"
        onClick={() => (closeCallback ? closeCallback() : setShowSelf(false))}
      >
        <i className="fa fa-times" title="Close" />
      </a>
      <h3>{title}</h3>
      <p className="message">
        {message}
        {' '}
        {link && cta && (<a href={link} className={className} target={target} style={style}>{cta}</a>)}
      </p>

    </div>
  );
};

export default SecondaryNotification;
